import {TokenContext} from "../js/schema/Contexts";
import React, {useContext, useState} from "react";
import {AxiosPost, AxiosPostWithoutState} from "../js/axios/AxiosPost";
import {apiUrl, authUrl} from "../js/schema/Environment";
import {useSnackbar} from "notistack";
import {Box, useTheme} from "@mui/system";
import LogoImage from '../img/logo_white.png';
import {TextFieldRow} from "../js/component/Inputs/TextFields";
import {Button, Grid, Typography} from "@mui/material";


function AuthPage() {
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const [currentToken, setCurrentToken] = useContext(TokenContext);
    const [user, setUser] = useState(
        {
            username: '',
            password: ''
        });

    const handleFieldChange = (fieldName, fieldValue) => {
        setUser((prevFormData) => ({
            ...prevFormData,
            [fieldName]: fieldValue,
        }));
    };

    console.log("AuthPage")

    const loginUser = (e) => {
        e.preventDefault();
        if(user.username.length === 0 || user.password.length === 0)
            enqueueSnackbar("Заполните поля логин и пароль", { variant: 'info' });
        else {
            AxiosPostWithoutState(apiUrl + "/student/login", null, user, null).then(({
                                                                                         loaded,
                                                                                         error,
                                                                                         data
                                                                                     }) => {
                if (error) {
                    enqueueSnackbar(error, {variant: 'error'});
                    console.log("Произошла ошибка при получении пользователя: \n" + error);
                } else {
                    setCurrentToken(data.token);
                }
            })
                .catch((error) => {
                    enqueueSnackbar("Произошла ошибка, попробуйте позднее", {variant: 'error'});
                });
        }
    }

    return (
        <div className="flex-col-vcenter-hcenter full-screen" style={{padding: "2rem", backgroundColor: '#002870'}}>
            <form className="logingroup"  onSubmit={loginUser}>
                <div className="group-995 flex-col-hcenter" >

                    <Box height={"20rem"} className="flex-row-vcenter-hcenter m-b-1">
                        <img src={LogoImage} style={{height: 'inherit'}}/>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid  item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input onChange={e => handleFieldChange('username', e.target.value)}
                                       placeholder="Логин"/>
                            </Box>
                            {/*<TextFieldRow name={"Логин"} value={user.username} onChangeFunction={ e => handleFieldChange('username', e.target.value)}/>*/}
                        </Grid>
                        <Grid item xs={12}>
                            <Box  className="field-input flex-row-vstart-hcenter">
                                <input onChange={e => handleFieldChange('password', e.target.value)}
                                       placeholder="Пароль"/>
                            </Box>
                        </Grid>
                    </Grid>
                    <Button variant="contained" sx={{ width: '100%', marginTop: '2rem', marginBottom: '1rem', borderRadius: '0.5rem', backgroundColor: theme.palette.info.contrast}} onClick={e => loginUser(e)}>
                        <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                            Войти
                        </Typography>
                    </Button>
                </div>
            </form>
        </div>
    )
}


export default AuthPage;
