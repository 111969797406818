import React from "react";
import { SnackbarProvider } from "notistack";
import { styled } from "@material-ui/core/styles";

const CustomSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
    "& .MuiSnackbarContent-root": {
        fontSize: "2rem", // Устанавливаем желаемый размер шрифта
    },
}));

export default CustomSnackbarProvider;
