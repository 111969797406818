import React, {useState} from "react";
import {TokenContext, PreloaderContext, NotifyModalContext} from "./schema/Contexts";
import AuthPage from "../page/AuthPage";
import MainPage from "../page/MainPage";

import '../css/custom.css';
import '../css/button.css';
import '../css/code.css';
import '../css/content.css';
import '../css/login.css';
import '../css/text.css';
import '../css/keyInput.css';
import '../css/notify_modal.css';

import Preloader from "./Preloader";
import UseToken from "./auth/UseToken";
import NotifyModal from "./component/modal/NotifyModal";
import {ThemeProvider} from "@mui/material";
import {baseTheme} from "./schema/theme/BaseTheme";
import {SnackbarProvider} from "notistack";
import CustomSnackbarProvider from "./schema/CustomSnackbarProvider";


function PageRouter() {

    const {currentToken, setToken} = UseToken();
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useState(false);
    const [notifyModalState, setNotifyModalState] = useState({
        class: 'inactive',
        title: '',
        description: '',
        closable: false
    });

    const urlParams = new URLSearchParams(window.location.search);
    const teamIdFromUrl = urlParams.get('teamId');


    if (currentToken) {
        try {
            const decodedToken = JSON.parse(atob(currentToken.split('.')[1]));
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp < currentTime) {
                setToken()
            }
        }catch (e) {
            setToken();
        }

    }


    if (!currentToken && !teamIdFromUrl) {
        return (
            <CustomSnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <ThemeProvider theme={baseTheme}>
                    <TokenContext.Provider value={[currentToken, setToken]}>
                        <AuthPage/>
                    </TokenContext.Provider>
                </ThemeProvider>
            </CustomSnackbarProvider>
        );
    }
    else{
        return (
            <CustomSnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <NotifyModalContext.Provider value={[notifyModalState, setNotifyModalState]}>
                    <ThemeProvider theme={baseTheme}>
                        <PreloaderContext.Provider value={[preloaderModalIsActive, setPreloaderModalIsActive]}>
                            <TokenContext.Provider value={[currentToken, setToken]}>
                                <MainPage/>
                                <Preloader/>
                                <NotifyModal/>
                            </TokenContext.Provider>
                        </PreloaderContext.Provider>
                    </ThemeProvider>
                </NotifyModalContext.Provider>
            </CustomSnackbarProvider>
        );
    }
}

export default PageRouter;
