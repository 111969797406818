import './App.css';
import PageRouter from "./js/PageRouter";

function App() {
  return (
      <PageRouter/>
  );
}

export default App;
