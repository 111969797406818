import React, {useContext, useEffect, useState} from "react";
import {NotifyModalContext} from "../../schema/Contexts";
import LogoImage from '../../../img/logo_white.png';
import {Box, useTheme} from "@mui/system";
import {Button, Typography} from "@mui/material";


function NotifyModal(){

    const theme = useTheme();

    const [visible, setVisible] = useState('invisible');
    const [notifyModalState, setNotifyModalState] = useContext(NotifyModalContext);

    useEffect(() => {
        if(notifyModalState.class !== 'inactive')
            setTimeout(
                function() {
                    setVisible('visible');
                },300
            );
    }, [notifyModalState, visible]);

    function handleNotify(){
        if(notifyModalState.closable === true) {
            setVisible('invisible');
            setNotifyModalState({
                class: 'inactive',
                text: ''
            });
        }
    }

    console.log("NotifyModal")


    var wrapperClass = 'hint-modal centeredWrapper ' + notifyModalState.class;
    return (
        <Box onClick={e => handleNotify('inactive','')} className={'flex-col-vcenter-hcenter ' + wrapperClass + ' ' + visible}>
            <div className="hint-modal-inner">
                <Box height={"20rem"} className="flex-row-vcenter-hcenter">
                    <img src={LogoImage} style={{height: 'inherit'}}/>
                </Box>
                {notifyModalState.class === "ERROR" ?
                    <Box>
                        <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>Ошибка</Typography>
                    </Box>
                    :
                    <></>
                }
                <Box marginTop={"1rem"}>
                    <Typography variant="h2"  textAlign={"center"} color={theme.palette.text.contrast}>{notifyModalState.title}</Typography>
                </Box>
                {notifyModalState.closable === true ?
                    <Box marginTop={"1rem"}>
                        <Button variant="contained" sx={{ width: '100%', marginTop: '2rem', marginBottom: '1rem', borderRadius: '0.5rem', backgroundColor: theme.palette.info.contrast}} onClick={e => handleNotify('inactive', '')}>
                            <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                                Закрыть
                            </Typography>
                        </Button>
                    </Box>
                    :
                    <></>
                }
            </div>
        </Box>
    )
}

export default NotifyModal;
