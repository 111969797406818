import {useState} from 'react';

function UseToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
    };

    return {
        currentToken: token,
        setToken: saveToken
    }
}

export default UseToken;
