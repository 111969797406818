import React, {useContext, useEffect, useState} from "react"
import {TokenContext, PreloaderContext, NotifyModalContext} from "../js/schema/Contexts";
import SubTitleRow from "../js/text/SubTitleRow";
import {Button, Grid, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import OtpInput from "react-otp-input";
import {AxiosGetWithoutState} from "../js/axios/AxiosGet";
import {useSnackbar} from "notistack";
import {AxiosPostWithoutState} from "../js/axios/AxiosPost";
import {Box, useTheme} from "@mui/system";
import TransparentImage from '../img/transparent.png';
import {apiUrl} from "../js/schema/Environment";
import UseWebSocket from "../js/component/UseWebSocket";
import MenuItem from "@mui/material/MenuItem";
import '../css/customInput.css';

export default function MainPage() {

    const {enqueueSnackbar} = useSnackbar();
    const [currentToken, setCurrentToken] = useContext(TokenContext);
    const [notifyModalState, setNotifyModalState] = useContext(NotifyModalContext);

    const [downloadParam, setDownloadParam] = useState({
            loaded: false,
            error: null,
            data: null
        }
    );
    const [teamData, setTeamData] = useState({
        id: 0,
        title: "",
        firstText: "",
        lastText: "",
        mediaFile: TransparentImage,
        name: "Ожидаем",
        color: "Ожидаем",
        balance: 0,
        currentSequenceId: 0,
        enteredCodes: [],
        sequenceList: [],
        timerTask: null,
        gameStatusForTeam: "DEVELOP",
    });

    const [time, setTime] = useState("00:00");

    UseWebSocket("/dozor/" + teamData.id.toString(), "TEAM_DATA", update, "team_view_update");
    UseWebSocket("/dozor/" + teamData?.id.toString(), "TIME", updateTime);

    function updateTime (newTime) {
        setTime(newTime);
    }


    function update (teamData) {
        console.log("TEAM_DATA update")
        setTeamData((prevControlsData) => {
            if (teamData === null) {
                return null;
            } else {
                return {
                    id: teamData.id,
                    title: teamData.title,
                    firstText: teamData.firstText,
                    lastText: teamData.lastText,
                    mediaFile: teamData.mediaFile,
                    name: teamData.name,
                    color: teamData.color,
                    balance: teamData.balance,
                    currentSequenceId: teamData.currentSequenceId,
                    enteredCodes: teamData.enteredCodes,
                    sequenceList: teamData.sequenceList,
                    timerTask: teamData.timerTask,
                    gameStatusForTeam: teamData.gameStatusForTeam,
                };
            }
        });
        updateTime( teamData.timerTask?.time);
    }


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const teamIdFromUrl = urlParams.get('teamId');
        if (!teamIdFromUrl) {
            AxiosGetWithoutState(
                apiUrl + "/dozor/user/findByStudentToken",
                null,
                currentToken)
                .then(({
                           loaded,
                           error: loadError,
                           data: team
                }) => {
                if (loadError) {
                    // enqueueSnackbar(loadError, {variant: "error"});
                    console.log("Произошла ошибка при подключении к игре: \n" + loadError);
                    setDownloadParam({
                        loaded: true,
                        error: loadError,
                        data: null
                    })
                } else {
                    setTeamData(team);
                    setDownloadParam({
                        loaded: true,
                        error: null,
                        data: team
                    })
                }
            }).catch(e => {
                enqueueSnackbar("Произошла ошибка при подключении к игре", {variant: "error"});
                console.log("Произошла ошибка при подключении к игре: \n" + e);
                setDownloadParam({
                    loaded: true,
                    error: e,
                    data: null
                })
            });
        } else {
            AxiosGetWithoutState(
                apiUrl + "/dozor/user/findById",
                {"teamId": teamIdFromUrl},
                currentToken)
                .then(({
                           loaded,
                           error: loadError,
                           data: team
                }) => {
                if (loadError) {
                    enqueueSnackbar(loadError, {variant: "error"});
                    console.log("Произошла ошибка при подключении к игре: \n" + loadError);
                    setDownloadParam({
                        loaded: true,
                        error: loadError,
                        data: null
                    })
                } else {
                    setTeamData(team);
                    setDownloadParam({
                        loaded: true,
                        error: null,
                        data: team
                    })
                }
            }).catch(error => {
                enqueueSnackbar("Произошла ошибка при подключении к игре", {variant: "error"});
                console.log("Произошла ошибка при подключении к игре: \n" + error);
                setDownloadParam({
                    loaded: true,
                    error: error,
                    data: null
                })
            });
        }
    }, []);

    if (downloadParam.error) {
        if(notifyModalState.class === 'inactive')
            setNotifyModalState({
                    class: 'ERROR',
                    title: 'Не удалось подключиться к игре, попробуйте позднее'
                }
            )
    } else if (!downloadParam.loaded) {
        return (
            <div className="full-screen flex-col-vcenter-hcenter">
                <CircularProgress/>
            </div>
        )
    }

    if (downloadParam.data)
        return (<DozorContent teamData={teamData} time={time}/>)
}

function DozorContent({teamData, time}) {
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();

    const [hintViewActive, setHintViewActive] = useState(false);
    let currentSequenceForTeam = teamData.sequenceList.filter(s => s.orderId === teamData.currentSequenceId)[0];
    const currentGameSequenceObject = currentSequenceForTeam?.dozorGameEntityType === 'POINT' ? currentSequenceForTeam?.dozorPoint : currentSequenceForTeam?.dozorTransition;

    console.log("currentGameSequenceObject");
    return (
        <Box className="full-screen flex-col-vcenter-hcenter bg-image" sx={{backgroundImage: teamData.mediaFile ? "url(" + apiUrl + teamData.mediaFile.url + ")" : "url(" + TransparentImage + ")"}}>
            <div className="fixed-top-container p-2">
                <div className="flex-col-space-between height-p-100">
                    <Box className="flex-row-vcenter-hcenter">
                        <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>{teamData.title}</Typography>
                    </Box>
                    {teamData.gameStatusForTeam === "ACTIVE" && currentSequenceForTeam?.dozorGameEntityType === 'POINT' && currentGameSequenceObject && currentGameSequenceObject.textHint && currentGameSequenceObject.textHint.length > 0 ?
                        <Box marginTop={"2rem"} className="flex-row-vcenter-hcenter" onClick={() => setHintViewActive(true)}>
                            <Typography variant="h1"  textAlign={"center"} color={theme.palette.error.main}>Подсказка</Typography>
                        </Box>
                        :
                        <></>
                    }
                </div>
            </div>
            {teamData.gameStatusForTeam === "ACTIVE" && currentSequenceForTeam?.dozorGameEntityType === 'POINT' ?
                <>
                    <CodeInput time={time} teamData={teamData}/>
                    <EnteredCodeView teamData={teamData} currentGameSequenceObject={currentGameSequenceObject}/>
                </>
                :
                <></>
            }
            {teamData.gameStatusForTeam === "ACTIVE" && currentSequenceForTeam?.dozorGameEntityType === 'TRANSITION' ?
                <div style={{marginBottom: "50%"}} className="flex-col-vcenter-hcenter">
                    <Box marginBottom={"3rem"}>
                        <Typography variant="hExtraLarge"  textAlign={"center"} color={theme.palette.text.contrast}>{time}</Typography>
                    </Box>

                    <Box >
                        <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>{currentGameSequenceObject.textHint}</Typography>
                    </Box>
                </div>
                :
                <></>
            }
            <WaitingView teamData={teamData}/>
            <FinishView teamData={teamData}/>
            <HintView teamData={teamData} currentGameSequenceObject={currentGameSequenceObject} hintViewActive={hintViewActive} setHintViewActive={setHintViewActive}/>
        </Box>
    )

}

function WaitingView({teamData}) {
    const theme = useTheme();
    if(teamData.gameStatusForTeam === "WAITING_ACTIVE")
        return (
            <div className="full-screen flex-col-vcenter-hcenter p-2 overlay">
                <Box marginBottom={"2rem"}>
                    <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>Игра скоро начнется</Typography>
                </Box>
                <Box >
                    <Typography variant="h2"  textAlign={"center"} color={theme.palette.text.contrast}>{teamData.firstText}</Typography>
                </Box>
            </div>
        )
}

function FinishView({teamData}) {
    const theme = useTheme();
    if(teamData.gameStatusForTeam === "FINISHED")
        return (
            <div className="full-screen flex-col-vcenter-hcenter p-2 overlay">
                <Box marginBottom={"2rem"}>
                    <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>Игра завершена</Typography>
                </Box>
                <Box >
                    <Typography variant="h2"  textAlign={"center"} color={theme.palette.text.contrast}>{teamData.lastText}</Typography>
                </Box>
            </div>
        )
}

function HintView({teamData, currentGameSequenceObject, hintViewActive, setHintViewActive}) {
    console.log("HintView");
    const theme = useTheme();
    if(hintViewActive === true)
        return (
            <Box className="full-screen flex-col-vcenter-hcenter p-2 overlay" onClick={e => setHintViewActive(false)}>
                <Box >
                    <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>{currentGameSequenceObject.textHint}</Typography>
                </Box>
            </Box>

        )
}

function EnteredCodeView({teamData, currentGameSequenceObject}) {
    const theme = useTheme();

    let codes = teamData.enteredCodes.filter(c => c.dozorPointId === currentGameSequenceObject.id);
    let balance = 0;
    codes.forEach(c => c.correct === true ? balance = balance + c.cost : balance = balance - c.cost);

    return (
        <div className="fixed-bottom-container p-2" style={{maxHeight: "25%", height: "25%"}} >
            <Grid container spacing={1} className={"flex-row-space-between"} pb={"1rem"} mb={"1rem"} borderBottom={"0.2rem solid whitesmoke"}>
                <Grid item >
                    <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>Баланс</Typography>
                </Grid>
                <Grid item >
                    <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>{balance}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" style={{maxHeight: "80%", overflow: "auto"}}>
                {codes.map((code, index) => (
                    <Grid key={index} item xs={12} >
                            <Grid  container spacing={1} className={"flex-row-space-between"}>
                                <Grid item >
                                    <Typography variant="h1"  textAlign={"center"} color={theme.palette.text.contrast}>{code.value}</Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant="h1"  textAlign={"center"} color={code.correct === true ? theme.palette.success.main : theme.palette.error.main}>{code.correct === true ? '+' + code.cost : '-' + code.cost}</Typography>
                                </Grid>
                            </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

function CodeInput({time, teamData}) {
    const [preloaderModalIsActive, setPreloaderModalIsActive] = useContext(PreloaderContext);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const [currentToken, setCurrentToken] = useContext(TokenContext);

    const [code, setCode] = useState(Array(6).fill(''));

    const inputRefs = Array.from({ length: 6 }).map(() => React.createRef());

    const handleInputChange = (value, index) => {
        if (/^[0-9a-zA-Z]$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (index < 5) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (code[index] === '') {
                if (index > 0) {
                    inputRefs[index - 1].current.focus();
                }
            } else {
                const newCode = [...code];
                newCode[index] = '';
                setCode(newCode);
            }
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const codeString = code.join('');
        let params = {"code": codeString};
        if (teamData?.id !== 0)
            params = {"code": codeString, "teamId": teamData.id};

        setPreloaderModalIsActive(true);
        AxiosPostWithoutState(
            apiUrl + "/dozor/user/code/save",
            params, null, currentToken)
            .then(({
                       loaded,
                       error,
                       data
            }) => {
            if (error) {
                enqueueSnackbar(error, {variant: "error"});
                console.log("Произошла ошибка при отправке кода: \n" + error);
                setCode(Array(6).fill(''));
            } else {
                enqueueSnackbar("Отправлен", {variant: "info"});
                setCode(Array(6).fill(''));
            }
            setPreloaderModalIsActive(false);
        }).catch(error => {
            setPreloaderModalIsActive(false);
            enqueueSnackbar("Произошла ошибка при отправке кода", {variant: "error"});
            console.log("Произошла ошибка при отправке кода: \n" + error);
        });
    }

    return (
        <div className="fixed-center-container flex-col-vcenter-hcenter">
            <Box marginBottom={"3rem"}>
                <Typography variant="hExtraLarge"  textAlign={"center"} color={theme.palette.text.contrast}>{time}</Typography>
            </Box>
            <Typography variant="h1" textAlign={"center"} color={theme.palette.text.contrast}>Ввести код</Typography>
            <Box sx={{ width: "80vw", mt: "2rem" }}>
                <Grid container spacing={2} >
                    {code.map((value, index) => (
                        <Grid item key={index} xs={2}>
                            <Box width={"-webkit-fill-available"} height={"100%"}>
                                <input
                                    key={index}
                                    type={(index === 2 || index === 3) ? "text" : "number"}
                                    value={value}
                                    onChange={(e) => handleInputChange(e.target.value, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={inputRefs[index]}
                                    maxLength={1}
                                    className="otp-input"
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <div className="key-input-wrapper centeredWrapper">
                <div style={{width: "100%"}}>
                    <Button variant="contained" sx={{ width: '100%', marginTop: '2rem', marginBottom: '1rem', borderRadius: '0.5rem', backgroundColor: theme.palette.info.contrast}} onClick={handleSubmit}>
                        <Typography variant="button" sx={{textWrap :"nowrap", padding: '1rem 2rem'}}  color={theme.palette.text.primary}>
                            Отправить
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    )

}

